@import "../../../styles/margin";
@import "../../../styles/width";
@import "../../../styles/colors";

.bannerContainer {
  margin: $margin-centered;
  width: $width-default;
  max-width: $width-content-layout;
  height: auto;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;

  figure {
    margin:$margin-zero $margin-zero 10px;
  }

  figcaption {
    background-color: $secondary-white;
    color: $color-black;
    text-align: center;
    padding: 8px;
  }

  h3 {
    margin: $margin-zero;
  }

  img {
    width: $width-default;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  }
}
