.image-title-container {
  display: flex;
  align-items: center;
  margin: 20px;
  padding: 15px;
  border: 3px solid #FF9900;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #F3F3F3;
  text-decoration: none; 
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    background-color: #FFF8E1; 
  }
    &:visited {
    text-decoration: none;
    color: inherit;
  }
}

.image {
  max-width: 150px;
  height: auto;
  margin-right: 20px;
  border-radius: 8px;
  border: 2px solid #232F3E;
}

.content {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #232F3E;
  margin: 0;
}

.image-title-container:hover .title {
  color: #FF9900;
}

a {
  text-decoration: none;
  &:visited {
    text-decoration: none;
  }
}