body {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1, h2, h3, h4, h5, h6, label {
  color: rgba(0, 0, 0, 0.87)
}

p, li, i {
  color: rgba(0, 0, 0, 0.6);
}
