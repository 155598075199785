@import "../../../styles/constants";
@import "../../../styles/colors";

.modalBox {
  position: "absolute";
  top: "50%";
  left: "50%";
  transform: "translate(-50%; -50%)";
  width: 400;
  border: "2px solid #000";
}
