// Position
$position-relative: relative;
$position-absolute: absolute;

// Display
$display-block: block;
$display-inline-block: inline-block;
$display-grid: grid;
$display-flex: flex;
$display-inline-flex: inline-flex;

// Fonts
$font-weight-lighter: 400;
$font-weight-normal: 500;
$font-weight-bold: 600;
$font-weight-bolder: 700;

// Font Size
$footer-font-size: 12px;

// Font family
$font-family-global: "Roboto", "Helvetica", "Arial", "sans-serif";

// Alignment
$vertical-align-middle: middle;
