@import "../../../styles/margin";
@import "../../../styles/breakPoints.scss";
@import "../../../styles/constants";

$global-margin-bottom: 20px;

.newsContainer {
  padding: 16px;
  h2 {
    margin-left: $margin-zero;
  }

  .article {
    display: $display-flex;
    text-align: justify;
    margin-bottom: $global-margin-bottom;

    .leftPanel {
      margin-right: $global-margin-bottom;
      @media (max-width: $medium-sm) {
        margin-right: $margin-zero;
      }
    }

    .newsListContainerWrapper {
      margin-top: 16px;
    }

    .newsImage {
      width: 400px;
      height: auto;

      @media (max-width: $medium-sm) {
        div {
          display: inline-block;
        }
        width: 100%;
        margin-right: $margin-zero;
        margin-bottom: $global-margin-bottom;
      }
    }

    .newsTitle,
    .newsDate,
    .newsDescriptionPage {
      margin: $margin-zero;
    }

    .newsTitle {
      font-size: 1.5rem;
      margin-bottom: 10px;
    }

    .newsDate {
      font-size: 0.9rem;
      color: gray;
      margin-bottom: 15px;
    }

    .newsDescriptionPage {
      font-size: 1rem;
    }

    @media (max-width: $medium-sm) {
      display: $display-block;
    }
  }

  hr {
    margin: 26px 0;
    border: 1px solid #FF9900;
  }
}

