// Primary colors
$color-white: #ffffff;
$secondary-white: #fafafa;
$color-black: #000000;
$color-secondary-black: #00000099;
$primary-color: #2c3e50;

// Neutral colors
$light-gray: #dddddd;

// CTA'S
$cta-orange: #ff8d29;
$cta-green: #49ff00;
$cta-purple: #d800a6;
$cta-blue: #39b5e0;

// Header
$background-header: #f0eeeb;

// Footer
$background-footer: #1b1b1b;
