@import "../../../styles/width";
@import "../../../styles/breakPoints.scss";

.pageWrapper {
  margin: 0 10px;
  @media (min-width: $medium-md) {
    margin: 0;
  }
  .content {
    margin-top: 80px;
    max-width: $width-content-layout;
    @media (min-width: $medium-md) {
      margin: 100px auto 50px;
    }
  }
}
