@import "../../../styles/margin";
@import "../../../styles/breakPoints.scss";
@import "../../../styles/constants";

.listContainer {
  display: $display-grid;
  gap: 16px;
  padding: 16px;
  img {
    height: 230px;
  }
}

@media (min-width: $medium-md) {
  .listContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: $medium-sm) {
  .listContainer {
    grid-template-columns: 1fr;
  }
}
