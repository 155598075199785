// Defaults
$width-default: 100%;
$width-inherit: inherit;
$width-fit-content: fit-content;

// Default grid width
$width-content-layout: 1110px;

// Default cards
$width-card: 590px;
