@import "../../../styles/constants";
@import "../../../styles/colors";

$footer-padding: 20px 0 20px 0;

.footerWrapper {
  background-color: $background-footer;
  height: auto;
  padding: $footer-padding;
  text-align: center;
  font-size: $footer-font-size;
  color: $color-white;
  font-weight: $font-weight-normal;
  display: $display-grid;
  font-family: $font-family-global;
  border-top: 4px solid $cta-blue;

  > span:not(:last-child){
    margin-bottom: 10px;
    cursor: pointer;
  }
}
