@import "../../../styles/constants";
@import "../../../styles/breakPoints.scss";

.newsListContainer {
  width: 100%;
  @media (min-width: $medium-sm) {
    max-width: 400px;
  }
  cursor: pointer;
}

.newsListHeader {
  display: $display-flex;
  align-items: center;
}

.newsListContainer .MuiAvatar-root,
.newsTitle {
  vertical-align: $vertical-align-middle;
  margin-right: 10px;
  display: $display-inline-flex;
}

.newsListContainer .MuiStack-root > div {
  display: $display-block;
}

.newsListContainer ,
.newsTitle {
  display: $display-inline-block;
  vertical-align: $vertical-align-middle;
  margin-right: 10px;
}

.newsDateList,
.newsDescription {
  margin-left: 5px;
  display: $display-block;
  margin-top: 10px;
}

.newsDescription {
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
