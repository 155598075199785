@import "../../../styles/margin";
@import "../../../styles/breakPoints.scss";
@import "../../../styles/constants";
@import "../../../styles/width";

.container {
  margin: $margin-centered;
  display: $display-grid;
  gap: 16px;
  padding: 16px 0;

  img {
    height: 350px;
  }
}

h2 {
  margin: $margin-centered;
  max-width: $large-lg;
  margin: 10px 0 10px 16px;
}

.customPageTitles {
  position: relative;
  letter-spacing:2px;
  padding: 4px;
  width: $width-fit-content;
  margin-bottom: 18px;
}

.customPageTitles::before, 
.customPageTitles::after {
  content: "";
  position: absolute;
  height: 2px;
  background-color: #c50000;
  width: 60%;
}

.customPageTitles::before {
  top: 0;
  left: 0;
}

.customPageTitles::after {
  bottom: 0;
  right: 0;
}

@media (min-width: $medium-md) {
  .container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: $medium-sm) {
  .container {
    grid-template-columns: 1fr;
  }
}