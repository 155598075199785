@import "../../../styles/width";
@import "../../../styles/colors";
@import "../../../styles/constants";

.headerWrapper {
  width: $width-default;

  > header {
    height: auto;
    background-color: $secondary-white;
    border-bottom: 4px solid $cta-blue;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .headerIcon {
    background: url("../../../assets/logos/logo.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 35px;
    height: 49px;
  }

  .navItems {
    color: $primary-color;
    display: $display-block;
    font-weight: $font-weight-bold;
  }
}
