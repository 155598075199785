@import "../../../styles/margin";
@import "../../../styles/breakPoints.scss";
@import "../../../styles/constants";
@import "../../../styles/width";
@import "../../../styles/colors";

.infoContainer {
  display: $display-flex;
  padding: 16px;
  margin: $margin-centered;

  ul {
    list-style-type: none;
    padding-left: 2px;
    li {
      display: $display-flex;
      align-items: center;
    }
  }
  .ctaBlue {
    background-color: $cta-blue;
    height: 40px;
    display: $display-flex;
    align-items: center;
    justify-content: center;
    color: $color-white;
    margin-top: 0;
    border-radius: 4px;
  }
  .primaryContent {
    max-width: 800px;
  }

  .secondaryContent {
    margin: 0 16px;
    @media (min-width: $medium-sm) {
      margin: 0 0 0 16px;
      -webkit-box-shadow: -2px 1px 6px -2px rgba(112,128,144,1);
      -moz-box-shadow: -2px 1px 6px -2px rgba(112,128,144,1);
      box-shadow: -2px 1px 6px -2px rgba(112,128,144,1);

    > div:first-child {
      padding: 16px 10px;
    }
    }
    
  }

  .artistList {
    margin-top: 30px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    p {
      padding: 0 16px 16px;
      text-align: center;
    }
  }

  .imgLogo {
    background: url("../../../assets/logos/logo.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 20px;
    height: 28px;
    margin-right: 10px;
  }

  label {
    font-weight: $font-weight-bold;
  }

  p {
    text-align: justify;
  }

  .customFestPageTitles {
    position: relative;
    letter-spacing:2px;
    padding: 4px;
    width: $width-fit-content;
    margin-bottom: 18px;
    > h3 {
      margin: 4px 0;
    }
  }

  .customFestPageTitles::before, 
  .customFestPageTitles::after {
    content: "";
    position: absolute;
    height: 2px;
    background-color: #c50000;
    width: 60%;
  }

  .customFestPageTitles::before {
    top: 0;
    left: 0;
  }

  .customFestPageTitles::after {
    bottom: 0;
    right: 0;
  }

  hr {
    margin: 26px 0;
    border: 1px solid #FF9900;
  }

}

@media (min-width: $medium-md) {
  .infoContainer {
    max-width: $width-content-layout;
  }
}

@media (max-width: $medium-sm) {
  .infoContainer {
    display: $display-inline-block;
  }
}
